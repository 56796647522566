import styled from 'styled-components';

const Arrow = styled('div')`
  border-left: solid 2px #4f4c4d;
  border-top: solid 2px #4f4c4d;
  height: 30px;
  width: 30px;
`;

const ArrowFilled = styled('div')`
  border: transparent;
  border-style: solid;
  border-width: 5px;
  height: 0px;
  width: 0px;
`;

const ArrowBottomFilled = styled(ArrowFilled)`
  border-top: solid 5px #504d4d;
`;

const ArrowLeft = styled(Arrow)`
  transform: rotate(-45deg);
`;

const ArrowRight = styled(Arrow)`
  transform: rotate(135deg);
`;

export { ArrowBottomFilled };
export { ArrowLeft };
export { ArrowRight };
export { default as Square } from './Square';
