import * as types from './../constants/ActionTypesBalance';
import axiosDispatch from './../axios/dispatch';

export function checkoutForm(params) {
  return dispatch => {
    return axiosDispatch(dispatch, '/payments/checkoutForm', params);
  }
}

export function payInModalToggle(params)
{
  return {
    type: types.PAY_IN_MODAL_TOGGLE,
    payload: params
  }
}
