import { connect } from 'react-redux';
import { toggle } from './../actions/Footer';
import { signOut } from './../actions/Auth';
import { createModalToggle as contactsCreate } from './../actions/Contacts';
import { createModalToggle as postsCreate } from './../actions/Posts';
import MenuBtn from './../components/MenuBtn';

const mapStateToProps = state => ({
  authorized: state.auth.jwt !== null
});

export default connect(mapStateToProps, { signOut, postsCreate, contactsCreate, toggle })(MenuBtn);
