import React from 'react';
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBBtn
} from 'mdbreact';

const Return = ({ isOpen, closeHandler }) => {
  return (
    <MDBModal isOpen={isOpen} toggle={closeHandler} centered>
      <MDBModalHeader>
        Повернення
      </MDBModalHeader>
      <MDBModalBody className="mb-0 modal-lg" style={{ textAlign: 'justify' }}>
        <h5>Повернення коштів за послуги</h5>
        <p>Відповідно до законодавства України, повернення сплачених коштів за інформаційні послуги, здійснюється лише за ненадані інформаційні послуги.</p>
        <h5>Процедура повернення</h5>
        <p>Клієнт звертається до Shucobi із запитом через електронну адрессу shusobi@gmail.com . Для повернення потрібно у листі вказати послуги якої немає в наявності, та контактну інформацію за якою ми зможемо з Вами зв’язатися.</p>
        <p>Повернення коштів відбувається виключно на картку, тобто безготівковою формою, протягом двох робочих тижнів після звернення.</p>
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn color="blue-grey" onClick={closeHandler}>Закрити</MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  );
};

export default Return;
