import Filters from './../../components/Filters';
import { getList, filtersSet } from './../../actions/Posts';
import { connect } from 'react-redux';

const filtersOptions = (filters, values) => {
  let rv = [];

  filters.map(el => {
    let filter = { id: el.id, label: el.title, options: [] };

    el.values.map(el => filter.options.push({
      text: el.title,
      value: String(el.id),
      checked: values[filter.id] === undefined ? false : values[filter.id].includes(String(el.id))
    }));

    rv.push(filter);

    return true;
  });

  return rv;
}

const mapStateToProps = state => ({
  filtersOptions: filtersOptions(state.filters, state.postsFilters.filtersValues),
  filtersValues: state.postsFilters.filtersValues,
  hashtags: state.hashtags
});

export default connect(mapStateToProps, { getList, filtersSet })(Filters);
