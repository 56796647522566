import React, { useEffect } from 'react';
import debounce from './../../helpers/debounce';
import { MDBContainer } from 'mdbreact';
import Loader from './../../containers/Posts/Loader';
import Tiles from './../../containers/Posts/Tiles';

export default props => {
  const nextPage = debounce(() => props.nextPage(), 1000);

  const windowOnScroll = () => {
    if ( document.documentElement.offsetHeight <= ( document.documentElement.scrollTop + window.innerHeight * 2 ) ) {
      nextPage();
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', windowOnScroll);

    return () => window.removeEventListener('scroll', windowOnScroll);
  });

  return (
    <MDBContainer fluid className="posts-list-wrapper">
      <div className="container-stripe left"></div>
      <div className="container-stripe right"></div>
      <Tiles />
      <Loader />
    </MDBContainer>
  );
}
