import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Image = ({ alt, className, source }) => {
  return (
    <img alt={alt} className={className} src={source} />
  );
};

Image.defaultProps = {
  alt: 'Image',
};

Image.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  source: PropTypes.string.isRequired,
};

export default styled(Image)`
  max-height: 100%;
  max-width: 100%;
`;
