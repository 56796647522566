import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'mdbreact';

import { getMe, balance } from './actions/Users';
import { getList as getPosts } from './actions/Posts';
import { getList as getColors } from './actions/Colors';
import { getList as getFilters } from './actions/Filters';
import { getList as getHashtags } from './actions/Hashtags';

import AuthModals from './components/Auth/Modals';
import Content from './containers/Content';
import MenuBtn from './containers/MenuBtn';
import Modals from './components/Modals';
import Routes from './routes';

const App = (props) => {
  useEffect(() => {
    props.getMe();
    props.balance();
    props.getPosts();
    props.getColors();
    props.getFilters();
    props.getHashtags();
  }, []);

  return (
    <div id="app">
      <AuthModals />
      <ToastContainer closeButton={false} />
      <MenuBtn />
      <Routes />
      <Modals />
    </div>
  );
}

const mapDispatch = { getMe, balance, getPosts, getColors, getFilters, getHashtags };

export default connect(null, mapDispatch)(App);
