import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { MDBIcon } from 'mdbreact';
import AboutUs from './Modals/AboutUs';
import Payment from './Modals/Payment';
import Return from './Modals/Return';
import Cooperation from './Modals/Cooperation';
import Offer from './Modals/Offer';

const Column = styled('div')``;
const Contacts = styled('p')``;
const BtnHide = styled('div')``;
const LogoWrapper = styled('div')``;
const Link = styled('p')``;
const Socials = styled('p')``;

const Footer = ({ className, isOpen, toggle, postsCreate }) => {
  const [ modal, setModal ] = useState(null);

  const closeModal = () => setModal(null);

  const openModal = (key) => () => setModal(key);

  return (
    <Fragment>
      <footer className={`${className} ${isOpen && 'open'}`}>
        <Column>
          <Link onClick={openModal('aboutUs')}>Про нас</Link>
          <Link onClick={openModal('payment')}>Оплата</Link>
        </Column>
        <Column>
          <Link onClick={openModal('return')}>Повернення</Link>
          <Link onClick={openModal('offer')} style={{ marginBottom: '10px' }}>Оферта</Link>
        </Column>
        <Column>
          <Link onClick={openModal('cooperation')}>Спiвпраця</Link>
          <Socials>
            <MDBIcon fab icon="facebook-square" />
            <a href="https://www.instagram.com/shusobi/" target="_blanc">
              <MDBIcon fab icon="instagram-square" />
            </a>
            <a href="https://www.youtube.com/channel/UCTXGDxGt0ddxLU_7lMDkM7Q" target="_blanc">
              <MDBIcon fab icon="youtube-square" />
            </a>
            <a href="https://telegram.me/shusobi" target="_blanc">
              <MDBIcon fab icon="telegram" />
            </a>
          </Socials>
        </Column>
        <div></div>
        <div>
          <Contacts>
            <span>shusobi@gmail.com</span>
            <a href="tel:+380687814140">+380 68 781 4140</a>
          </Contacts>
          <p>З радiстю вiдповiмо i допоможемо 10:00 - 19:00 Пн - Пт</p>
        </div>
        <BtnHide onClick={toggle}>
          <MDBIcon fas icon="angle-double-down" />
        </BtnHide>
      </footer>
      <AboutUs isOpen={modal === 'aboutUs'} closeHandler={closeModal} />
      <Payment isOpen={modal === 'payment'} closeHandler={closeModal} />
      <Return isOpen={modal === 'return'} closeHandler={closeModal} />
      <Cooperation isOpen={modal === 'cooperation'} closeHandler={closeModal} postsCreate={postsCreate} />
      <Offer isOpen={modal === 'offer'} closeHandler={closeModal} />
    </Fragment>
  );
};

export default styled(Footer)`
  align-items: center;
  background-color: #f3f3f3;
  border-top: solid 3px #adadad;
  bottom: 0px;
  display: grid;
  grid-template-columns: repeat(3, 200px) 1fr 422px 140px;
  height: 120px;
  left: 0px;
  padding-left: 60px;
  position: fixed;
  right: 0px;
  transition: transform 500ms ease-out;
  transform: translateY(150px);
  z-index: 1000;

  &.open {
    transform: translateY(0px);
  }

  ${Contacts} {
    display: flex;
    justify-content: space-between;

    & a {
      color: #788f9c;
      cursor: pointer;
    }
  }

  ${BtnHide} {
    justify-self: center;
    font-size: 25px;
    cursor: pointer;
  }

  ${Link} {
    cursor: pointer;
  }

  ${Socials} {
    font-size: 24px;
    margin-bottom: 10px;

    & i {
      color: #788f9c;
      cursor: pointer;
      margin-right: 10px;
    }
  }
`;
