import * as types from './../constants/ActionTypesUsers';
import axiosDispatch from './../axios/dispatch';

export function fetchData(data) {
  return {
    type: types.USER_FETCH_DATA,
    payload: data
  }
}

export function clear() {
  return {
    type: types.USER_CLEAR
  }
}

export function getMe() {
  return (dispatch, getState) => {
    if ( getState().auth.jwt === null ) return;

    axiosDispatch(dispatch, '/users/me')
      .then(data => dispatch(fetchData(data)))
  }
}

export function update(params) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axiosDispatch(dispatch, '/users/update', params)
        .then(data => {
          dispatch(fetchData(data));
          resolve(data);
        })
        .catch(error => reject(error));
    });
  }
}

export function balance() {
  return dispatch => {
    axiosDispatch(dispatch, '/transactions/balance')
      .then(data => dispatch({
        type: types.USER_BALANCE_FETCHED,
        payload: {
          balance: data.total,
        }
      }));
  }
}
