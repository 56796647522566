import React from 'react';
import { MDBModal, MDBModalBody, MDBModalFooter } from 'mdbreact';

const Modal = props => (
  <MDBModal isOpen={props.isOpen} toggle={props.closeHandler} centered>
    <MDBModalBody className="mb-0 modal-lg">
      {props.body}
    </MDBModalBody>
    <MDBModalFooter>
      {props.footer}
    </MDBModalFooter>
  </MDBModal>
);

export default Modal;
