import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Filters from './../../containers/Filters';
import { MDBIcon } from 'mdbreact';
import HashtagsInput from './../HashtagsInput';

const Navigation = props => {
  const hashtagsRef = {};
  const hashtagsHandler = hashtags => {
    props.filtersSet({ hashtags, page: 1 });

    if ( window.location.pathname !== '/' ) props.history.push('/');
  }

  const goCabinet = () => props.history.push('/cabinet/profile');

  const goHome = () => {
    props.filtersSet({ favorites: false, filtersValues: {}, hashtags: [], page: 1 });
    hashtagsRef.reset();
    props.history.push('/');
  };

  const [ collapse, setCollapse ] = useState(false);

  return (
    <div className="navigation-wrapper">
      <div className="navigation">
        <div className={'filters-button-collapse'+ (collapse ? ' active' : '')} onClick={() => setCollapse(!collapse)}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="logo-wrapper" onClick={goHome}>
          <img className="logo" src="/img/logo.png" alt="Шукаю собі" />
          <span className="title">Шукаю собі:</span>
        </div>
        <div className="search-wrapper">
          <MDBIcon icon="search" />
          <HashtagsInput hashtags={props.hashtags} hashtagsSelected={props.hashtagsSelected} hashtagsHandler={hashtagsHandler} hashtagsRef={hashtagsRef} />
        </div>
        <div className="action-wrapper">
          {props.favorites
            ? (<MDBIcon far={false} icon="heart" onClick={() => props.filtersSet({ favorites: false, page: 1 })} />)
            : (
              <MDBIcon far icon="heart" onClick={() => props.authorized
                ? props.filtersSet({ favorites: true, page: 1 })
                : props.modalOpen('signIn')
              } />
            )
          }
          {props.authorized
            ? (<MDBIcon far={false} icon="user" onClick={goCabinet} />)
            : (<MDBIcon far icon="user" onClick={() => props.modalOpen('signIn')} />)
          }
        </div>
      </div>
      <div className="border-middle"></div>
      <div className={'filters-bottom-wrapper'+ (collapse ? ' active' : '')}>
        <Filters />
        <div className="back" onClick={() => setCollapse(false)}></div>
      </div>
    </div>
  );
}

export default withRouter(Navigation);
