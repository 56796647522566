import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MDBIcon } from 'mdbreact';

const Tiles = ({
  avatar,
  blanc,
  id,
  isAuthorized,
  isFavorite,
  title,
  ...props
}) => {
  const favoriteAdd = id => event => {
    event.preventDefault();
    event.stopPropagation();
    props.favoritesAdd(id);
    setFavorite(true);
  }

  const favoriteRemove = id => event => {
    event.preventDefault();
    event.stopPropagation();
    props.favoritesRemove(id);
    setFavorite(false);
  }

  const signIn = event => {
    event.preventDefault();
    event.stopPropagation();
    props.signIn('signIn');
  }

  const [ favorite, setFavorite ] = useState(isFavorite);

  return (
    <Link
      className={(favorite ? 'favorite' : '') +' posts-item'}
      target={blanc ? '_blanc' : '_self'}
      to={'/posts/'+ id}
    >
      <div className="avatar-border-back"></div>
      <div className="avatar-border"></div>
      <div className="avatar-wrapper">
        <img className="img-fluid avatar" src={avatar} alt={title} />
      </div>
      <div className="title-wrapper">
        <div className="title-border"></div>
        <div className="title">
          <span>{title}</span>
          {!isAuthorized
            ? (<MDBIcon className="float-right" far icon="heart" onClick={signIn} />)
            : favorite
              ? (<MDBIcon className="float-right" far={false} icon="heart" onClick={favoriteRemove(id)} />)
              : (<MDBIcon className="float-right" far icon="heart" onClick={favoriteAdd(id)} />)
          }
        </div>
      </div>
    </Link>
  );
}

export default Tiles;
