import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import NavbarLeft from './CabinetLeft';
import NavbarTop from './CabinetTop';
import { Container } from 'mdbreact';

const DoubleNavigation = ({ balance, signOut, payInModalToggle, children }) => {
  const [ navbarLeftOpen, setNavbarLeftOpen ] = useState(false);
  const [ windowWidth, setWindowWidth ] = useState(window.innerWidth);

  const handleResize = () => setWindowWidth(window.innerWidth);

  const navbarLeftToggle = () => setNavbarLeftOpen(!navbarLeftOpen);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  });

  const breakWidth = 1300;
  const mainStyle = {
    margin: '0 6%',
    paddingTop: '5.5rem',
    paddingLeft: windowWidth > breakWidth ? '240px' : '0'
  };

  return (
    <BrowserRouter>
      <div className="fixed-sn white-skin cabinet-wrapper">
        <NavbarLeft navbarLeftOpen={navbarLeftOpen} breakWidth={breakWidth} />
        <NavbarTop
          windowWidth={windowWidth}
          breakWidth={breakWidth}
          navbarLeftToggle={navbarLeftToggle}
          payInModalToggle={payInModalToggle}
          signOut={signOut}
          balance={balance}
        />
        <main style={mainStyle}>
          <Container fluid>
            {children}
          </Container>
        </main>
      </div>
    </BrowserRouter>
  );
};

export default DoubleNavigation;
