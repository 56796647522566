import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { MDBBtnFixed, MDBBtnFixedItem, MDBTooltip } from 'mdbreact';

const MenuBtn = props => {
  const [ state, setState ] = useState({
    isOpen: false,
    buttonStyle: {
      transform: 'scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0)',
      opacity: '0'
    }
  });

  const onHover = () => {
    setState({
      isOpen: true,
      buttonStyle: {
        transform: "scaleY(1) scaleX(1) translateY(0) translateX(0)",
        opacity: "1"
      }
    });
  }

  const onLeave = () => {
    setState({
      isOpen: false,
      buttonStyle: {
        transform: "scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0)",
        opacity: "0"
      }
    });
  }

  const onClick = () => state.isOpen ? onLeave() : onHover();

  const signOut = () => {
    props.signOut();
    onLeave();
  }

  return (
    <MDBBtnFixed
      className="menu-btn"
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      onClick={onClick}
      color="grey"
      icon="cog"
      floating
      disabled
    >
      <MDBBtnFixedItem
        onClick={signOut}
        buttonStyle={state.buttonStyle}
        color="grey"
        icon="sign-out-alt"
        className={!props.authorized ? 'display-hidden' : ''}
        title="Вихід"
      />
      <MDBBtnFixedItem
        onClick={() => props.postsCreate({ isOpen: true })}
        buttonStyle={state.buttonStyle}
        color="grey"
        icon="upload"
        className={!props.authorized ? 'display-hidden' : ''}
        title="Завантажити модель"
      />
      <MDBBtnFixedItem
        onClick={() => props.contactsCreate({ isOpen: true })}
        buttonStyle={state.buttonStyle}
        color="grey"
        icon="envelope"
        title="Залишити звернення"
      />
      <MDBBtnFixedItem
        onClick={props.toggle}
        buttonStyle={state.buttonStyle}
        color="grey"
        icon="info-circle"
      />
    </MDBBtnFixed>
  );
}

export default withRouter(MenuBtn);
