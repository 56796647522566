import { connect } from 'react-redux';
import { getItem, favoritesAdd, favoritesRemove, getArchiveLink } from './../../actions/Posts';
import { modalOpen as signIn } from './../../actions/Auth';
import { payInModalToggle } from './../../actions/Balance';
import { balance } from './../../actions/Users';
import View from './../../components/Posts/View';

const mapStateTo = state => ({
  balance: state.user.balance,
  colors: state.colors,
  auth: ! ( state.auth.jwt === null )
});

const mapDispatch = {
  getItem,
  favoritesAdd,
  favoritesRemove,
  getArchiveLink,
  signIn,
  getBalance: balance,
  payInModalToggle
};

export default connect(mapStateTo, mapDispatch)(View);
