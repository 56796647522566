import React from 'react';
import {
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn
} from 'mdbreact';

const Offer = ({ isOpen, closeHandler }) => {
  return (
    <MDBModal isOpen={isOpen} toggle={closeHandler} size="lg" centered>
      <MDBModalBody className="mb-0" style={{ textAlign: 'justify' }}>
        <h5 className="text-center">ПУБЛІЧНИЙ ДОГОВІР </h5>
        <h5 className="text-center">(ДОГОВІР ПУБЛІЧНОЇ ОФЕРТИ) </h5>
        <p className="text-center">про надання інформаційних послуг</p>
        <p>Цей Договір, в якому одна сторона Фізична особа - підприємець Тюріна Ксенія Іванівна, зареєстрована в Єдиному державному реєстрі юридичних осіб та фізичних осіб – підприємців в установленому чинним законодавством порядку та який є платником єдиного податку (надалі - Виконавець), з одного боку, і будь-яка особа, яка прийняла (акцептувала) дану пропозицію (надалі - Замовник), з іншого боку, далі разом - Сторони, а кожний окремо - Сторона, уклали даний Договір (далі - Договір), адресований необмеженому колу осіб, який є офіційною публічною пропозицією Виконавця, укласти з будь-яким Замовником Договір про надання інформаційних послуг. При замовленні та оплаті Послуг Виконавця, Замовники приймають умови цього Договору про нижченаведене.</p>
        <h5>1. ЗАГАЛЬНІ ПОЛОЖЕННЯ</h5>
        <p>1.1. Цей Договір укладається шляхом надання повної й безумовної згоди (акцепту) Замовника на укладення Договору в повному обсязі, без підпису письмового примірника Договору Сторонами.</p>
        <p>1.2. Договір має юридичну силу відповідно до ст.ст. 633, 641, 642 Цивільного кодексу України і є рівносильним Договору, підписаному Сторонами.</p>
        <p>1.3. Замовник підтверджує факт ознайомлення та згоди з усіма умовами цього Договору в повному обсязі шляхом акцептування.</p>
        <p>1.4. Будь-яка з наступних дій вважається акцептом цього договору публічної оферти:</p>
        <ul>
          <li>факт реєстрації Замовника на Веб-сайті Виконавця та оформлення Замовлення Послуг Виконавця на Веб-сайті Виконавця https://shusobi.com/;</li>
          <li>оплата Послуг Виконавця на умовах та в порядку, визначеними цим Договором та на відповідних сторінках Веб-сайту Виконавця https://shusobi.com/;</li>
          <li>письмове (в т. ч в електронній формі засобами електронної пошти) повідомлення Замовника про прийняття умов цього Договору на адресу електронної пошти, зазначену на сайті https://shusobi.com/.</li>
        </ul>
        <p>1.5. Укладаючи цей Договір Замовник автоматично погоджується з повним та безумовним прийняттям положень цього Договору, цін на Послуги та всіх Додатків, які є невід’ємними частинами Договору.</p>
        <p>1.6. Якщо Замовник не згодний з умовами Договору, він не має права укладати цей Договір, а також не вправі користуватися Послугами за цим Договором.</p>
        <h5>2. ТЕРМІНИ І ВИЗНАЧЕННЯ</h5>
        <p>«Договір публічної оферти» - публічний договір, зразок якого розміщений на Веб-сайті https://shusobi.com/.</p>
        <p>«Акцепт» - надання Замовником повної й безумовної згоди на укладення даного Договору в повному обсязі, без підпису письмового примірника Договору Сторонами.</p>
        <p>«Сайт» - інтернет-сайт «Шукаю собі», розміщений на домені shusobi.com та доступний користувачу за допомогою Інтернет з’єднання через сайт, мобільну версію сайту, який є інформаційною платформою, що є результатом інтелектуальної власності у формі програми для електронно-обчислювальних машин.</p>
        <p>«Послуги» - послуги з розміщення на Інформаційній платформі Виконавця товару Замовника, які надаються Виконавцем, види та вартість яких визначена на Веб-сайті https://shusobi.com/ та зазначені Виконавцем у відповідному розділі Договору.</p>
        <p>«Замовник» - будь-яка дієздатна фізична, юридична особа, фізична особа-підприємець, що пройшла процедуру реєстрації на Веб-сайт https://shusobi.com/ та акцептувала даний Договір.</p>
        <p>«Виконавець» - суб’єкт господарювання, фізична особа – підприємець Тюріна Ксенія Іванівна, що надає послуги у сфері інформатизації, та яка є Правовласником Веб-сайту https://shusobi.com/, реквізити якої зазначено у розділі 12 даного Договору.</p>
        <p>«Замовлення» - вибір окремих позицій з переліку послуг, зазначених Замовником при розміщенні замовлення і проведенні оплати.</p>
        <p>«Реєстрація» - проходження Користувачем процедури безкоштовної, добровільної реєстрації, в результаті якої для Користувача буде створений унікальний обліковий запис – Особистий кабінет.</p>
        <p>«Сервіс» - комплекс технічних та технологічних можливостей Веб-сайту, доступ до яких надаються Виконавцем Замовнику після проходження процедури Реєстрації.</p>
        <p>«Особистий кабінет» - обліковий запис Замовника на Веб-сайті https://shusobi.com/, який адмініструється Виконавцем і через який Замовник отримує доступ до архівів, у яких зібрана вся інформація про об’єкти у відповідних програмах необхідних для створення дизайну та/або он-лайн виставок та/або бізнес-показів та/або майстер-класів тощо.</p>
        <p>«Контент» - розміщені на Веб-сайті Виконавця елементи дизайну, декору, ілюстрацій, графічного зображення, фотографій та інших об’єктів, та є результатом інтелектуальної діяльності Виконавця.</p>
        <h5>3. ПРЕДМЕТ ДОГОВОРУ</h5>
        <p>3.1. Виконавець зобов’язується на умовах та в порядку, визначених цим Договором, надати Замовнику інформаційні Послуги, а Замовник зобов’язується на умовах та в порядку, визначених цим Договором, прийняти та оплатити замовлені Послуги.</p>
        <p>3.2. Сторони підтверджують, що даний Договір не є фіктивним чи удаваним правочином або правочином, укладеним під впливом тиску чи обману.</p>
        <h5>4. ПОРЯДОК ОФОРМЛЕННЯ ЗАМОВЛЕННЯ ТА НАДАННЯ ПОСЛУГ</h5>
        <p>4.1. Послуги надаються на підставі замовлення Замовника оформленому на Веб-сайті.</p>
        <p>4.2. Послуги за цим Договором надаються Виконавцем за умови попередньої оплати Замовником вартості Послуг.</p>
        <p>4.3. Замовник, використовуючи функціональні можливості Сайту, через відповідний розділ Особистого кабінету, вносить грошові кошти в рахунок авансу за майбутні покупки Послуг, які надаються Виконавцем та розміщені на інформаційній платформі. Внесення грошових коштів відображається на індивідуальному «демо-рахунку», який надається Замовнику після проходження процесу Реєстрації.</p>
        <p>4.4. Доступ до Послуги, після її оплати Замовником, отримується автоматично.</p>
        <p>4.5. У разі неотримання Замовником доступу до придбаної послуги, Замовник повинен звернутися до Виконавця шляхом надсилання листа на електронну пошту: shukaiusobi@gmail.com, за номерами телефонів на Сайті Виконавця або будь-яким іншим способом.</p>
        <p>4.6. Доступ до інформації про Послуги надається Замовнику, шляхом відправлення відповідного Посилання. Забороняється передача доступу до Особистого кабінету третім особам.</p>
        <p>4.7. Замовник самостійно несе відповідальність за збереження конфіденційності та безпеки доступу до свого Особистого кабінету, віртуальної виставки та інших елементів Сайту, а також за всі дії, що відбуваються за допомогою Особистого кабінету, і зобов’язується невідкладно повідомляти Виконавця про будь-яке порушення безпеки Особистого кабінету.</p>
        <p>4.8. У разі відсутності можливості надати Послугу, Виконавець зобов’язаний повідомити про це Замовника шляхом надсилання листа електронною поштою на вказану адресу Замовника.</p>
        <p>4.9. Замовник має право замінити відсутню Послугу іншою, доступною Послугою, з аналогічною вартістю або відмовитися від даної Послуги, шляхом анулювання замовлення.</p>
        <p>4.10. Для отримання Послуги Замовник самостійно забезпечує дотримання належних технічних умов для персонального комп’ютера або іншого мобільного пристрою.</p>
        <p>4.11. Використання Замовником отриманої інформації і матеріалів (архівів, у яких зібрана вся інформація про об’єкти у відповідних програмах, необхідних для створення дизайну) допускається тільки в особистих цілях і для особистого використання.</p>
        <p>4.12. Виконавець має право відмовитись від надання послуг в односторонньому порядку і анулювати доступ Замовника до послуги в разі встановлення фактів передачі Замовником Персонального посилання третім особам, або поширення матеріалів та інформації, отриманих в результаті отримання Послуг, третім особам.</p>
        <p>4.13. Послуга вважається наданою у повному обсязі та належної якості з моменту її оплати Замовником та отримання відповідного посилання доступу в Особистому кабінеті Замовника.</p>
        <p>4.14. Для отримання Акту наданих послуг у паперовій або електронній формі Замовник надсилає лист на електронну адресу shusobi@gmail.com із зазначенням особистих реквізитів та дати оплати Послуг.</p>
        <p>4.15. У разі, якщо Замовник не звернувся до Виконавця з проханням надати Акт наданих послуг у паперовій або електронній формі до 12 (дванадцятого) числа місяця, наступного за місяцем надання послуг, за відсутності зауважень та повідомлень від Замовника, Акт наданих послуг вважається ним прийнятий, а послуги – наданими в повному обсязі та належної якості.</p>
        <h5>5. ВАРТІСТЬ ПОСЛУГ ТА ПОРЯДОК РОЗРАХУНКІВ</h5>
        <p>5.1. Ціна кожної окремої Послуги визначається Виконавцем та зазначається на відповідній сторінці Веб-сайту https://shusobi.com/.</p>
        <p>5.2. Замовник оплачує Послуги Виконавця на підставі цього Договору у Національній валюті України - гривні.</p>
        <p>5.3. Оплата Послуг здійснюється шляхом:</p>
        <p>5.3.1. перерахування грошових коштів на поточний рахунок Виконавця (якщо Замовником є фізична або юридична особа) або</p>
        <p>5.3.2. за допомогою інших платіжних засобів, які вказані на Веб-сайті Виконавця (якщо Замовником є фізична особа).</p>
        <p>5.4. Моментом оплати Послуг вважається час зарахування грошових коштів на поточний рахунок Виконавця.</p>
        <p>5.5. Замовник самостійно та за свій рахунок сплачує вартість послуг третіх осіб, якщо це необхідно для отримання Послуг Виконавця за цим Договором (наприклад, послуги доступу до мережі Інтернет та інші).</p>
        <h5>6. ПРАВА ТА ОБОВ’ЯЗКИ ЗАМОВНИКА</h5>
        <p>6.1. Замовник має право:</p>
        <ul>
          <li>Отримувати Послуги на умовах цієї Оферти;</li>
          <li>Отримати від Виконавця інформацію про надані Послуги, умови їх надання, направивши відповідний запит на електронну пошту, вказану на Сайті;</li>
          <li>Входити на онлайн-вистави, конференції, користуватися інформаційною базою під своїм обліковим записом одночасно тільки з однієї точки доступу (персональний комп’ютер, ноутбук, нетбук, планшет, смартфон і ін.);</li>
          <li>Виконувати налаштування Особистого кабінету, змінювати пароль та особисті данні;</li>
          <li>Після Реєстрації завантажувати, зберігати, публікувати та розповсюджувати інформацію, яка стосується Інформаційного пакету, завантаженого Користувачем, доступ до яких надає іншим Користувачам Виконавець, та повністю відповідає чинному законодавству України.</li>
          <li>Замовник користується всіма правами споживача відповідно до чинного законодавства України, що регулює дані правовідносини.</li>
          <li>Замовник має право звернутись до Виконавця щодо оформлення угоди про електронний документообіг.</li>
        </ul>
        <p>6.2. Замовник зобов’язаний:</p>
        <ul>
          <li>Оплатити Послуги в повному обсязі в порядку і строки, передбачені цією офертою;</li>
          <li>Надати Виконавцю актуальну інформацію про засоби зв’язку для направлення інформаційних матеріалів, а також для зв'язку з Виконавця з Замовником в рамках надання Послуг, згідно з цією офертою. Замовник несе відповідальність за достовірність такої інформації;</li>
          <li>Самостійно і своєчасно знайомитися з інформацією про розклад, вартість та умови надання Послуг, яка надається йому на Сайті або шляхом надсилання електронних листів на електронну адресу, вказану Замовником. Замовник не має право пред'являти Виконавцю претензії в разі несвоєчасного ознайомлення або не ознайомлення з інформацією на Сайті. Замовник приймає на себе всю відповідальність за наслідки несвоєчасного ознайомлення або не ознайомлення з інформацією, що розміщується для нього Виконавцем на Сайті.</li>
        </ul>
        <h5>6.3. Замовнику забороняється:</h5>
        <ul>
          <li>Передавати та/або надавати доступ до Послуги будь-яким третім особам, а також отримувати Послуги спільно з третіми особами;</li>
          <li>Поширювати недостовірну, неправдиву інформацію, інформацію що ганьбить честь, гідність, ділову репутацію Виконавця, магазинів, фізичних осіб, адміністраторів, та інших осіб, праця яких представлена на Сайті;</li>
          <li>Копіювати, поширювати будь-яким способом аудіо-, відео-, інформаційні матеріали, записи онлайн виставки (вебінару, тренінгу), отримані в рамках цієї оферти;</li>
          <li>Реєструватися на Сайті від імені або замість іншої особистості («фальшивий особистий кабінет»);</li>
          <li>Використовувати без спеціального дозволу Виконавця автоматизовані скрипти (програми, боти тощо) для збору інформації на Сайті чи взаємодії з сайтом та його функціоналом;</li>
          <li>Відтворювати, дублювати, копіювати, продавати, здійснювати різноманітні операції та перепродавати доступ до використання Сайту, у тому числі для доступу необмеженого кола осіб, за винятком випадків, коли такі дії були прямо дозволені Користувачу відповідно з умовами окремої угоди з Виконавцем;</li>
          <li>Здійснювати розміщення інформації, яка, на особисту думку Виконавця, є небажаною, не відповідає цілям створення Сайту, суперечить інтересам інших Замовників чи з будь-яких причин є небажаною для розміщення на Сайті;</li>
          <li>Використовувати програмне забезпечення чи здійснювати дії, направлені на порушення нормального функціонування Сайту чи персональної сторінки Замовника;</li>
          <li>Замовник несе повну особисту відповідальність за будь яку інформацію, яку розміщує на Сайті, повідомляючи іншим особам, у тому числі за відповідність цієї інформації нормам українського та міжнародного права. Порушення Замовником даної норми та діючого законодавства України та норм міжнародного права тягне за собою громадянського-правову, адміністративну та кримінальну відповідальність.</li>
          <li>Використовувати отриману від Виконавця інформацію, включаючи (без обмеження) з метою створення подібної і/або конкурентної послуги або сервісу, або з метою отримання комерційної або фінансової вигоди без попереднього узгодження з Виконавцем;</li>
        </ul>
        <h5>7. ПРАВА ТА ОБОВ’ЯЗКИ ВИКОНАВЦЯ</h5>
        <p>7.1. Виконавець самостійно та на власний розсуд здійснює поточне управління Сайтом, визначає його структуру, зовнішній вигляд, дозволяє чи обмежує доступ Замовникам до Сайту при порушенні умов даного Договору. Виконавець має право використовувати функціональні ті технічні можливості Сайту, забезпечуючи відображення розміщення на Сайті Контенту, на власний розсуд, у тому числі для цілей відображення інформації.</p>
        <p>7.2. Виконавець самостійно приймає рішення про порядок розміщення на Сайті матеріалів рекламного характеру на будь якій сторінці Сайту, у тому числі: контентної реклами, банерів, інтерактивних відео-, анімаційних чи інших рекламних роликів. Виконавець також самостійно приймає рішення про участь в партнерських програмах та інше.</p>
        <p>7.3. Щодо забезпечення можливості взаємодії між Замовником, у тому числі надання Замовнику можливості самостійно робити ти чи інші дії на Сайті, Виконавець виступає виключно як особа, яке надає технологічну можливість такого взаємозв’язку з використання Сайту. Пов’язана з таким взаємозв’язком передача, зберігання та забезпечення доступу за допомогою мережі Інтернет та Сайту до наданої Замовнику інформації, графічних зображень, іншим матеріалам та контенту, відбувається без зміни таких матеріалів чи впливу на їх зміст з боку Виконавця, якщо дана інформація не суперечить умовам даного Договору.</p>
        <p>7.4. Виконавець має право:</p>
        <ul>
          <li>В односторонньому порядку призупинити надання послуг за цим Договором у випадку порушення Замовником умов цього Договору;</li>
          <li>Самостійно визначати форми і способи надання Послуги, з огляду на умови даної оферти, інших, підписаних з Замовником договорів і вимоги чинного законодавства України та умови Договору;</li>
          <li>В односторонньому порядку визначити вартість Послуг;</li>
          <li>Отримувати від Замовника будь-яку інформацію, необхідну для виконання своїх зобов’язань за Договором. У разі неподання інформації, неповного або неправильного уявлення інформації, Виконавець має право призупинити надання Послуг за Договором до подання Замовником такої інформації;</li>
          <li>Здійснювати масову розсилку інформаційних повідомлень (у тому числі рекламних) на електронну адресу Замовника. При цьому такі повідомлення повинні містити посилання для відмови від розсилки, яка дозволяє Замовнику самостійно відмовитися від отримання даної розсилки;</li>
          <li>В односторонньому порядку вносити зміни в умови даної публічної оферти шляхом розміщення нової редакції її на Сайті;</li>
          <li>Відмовити Замовнику у Реєстрації або тимчасово призупинити доступ до Особистого кабінету чи видалити Особистий кабінет Замовника з попереднім попередженням про причини шляхом направлення повідомлення про це на електронну адресу Замовника;</li>
          <li>Відправляти Замовнику на вказану при реєстрацію/чи змінену ним електронну адресу інформаційні повідомлення про важливі події, які відбуваються у рамках Сайту, чи пов’язані з ним;</li>
          <li>Удосконалювати Сайт та розширювати його можливості;</li>
          <li>Видаляти без попередження любий Особистий контент, у тому числі Контент, який на думку Виконавця порушує та/чи може порушувати чинне законодавство України, норми міжнародного права, умови даного Договору, права інших Замовників чи третіх осіб, заподіювати шкоду чи створювати загрозу здійснення шкоди та/чи безпеки;</li>
          <li>Виконавець має право проводити перевірку достовірності інформації, які надав Замовник у Особистому кабінеті, подавати запит на відповідні відомості у Замовника, які необхідні при правильної ідентифікації Замовника, вимагати правовстановлюючі документи, які свідчать про те, що саме Замовник є автором, якому у відповідності до чинного законодавства України та міжнародними правовими актами належить конкретний Контент;</li>
          <li>У цілях достовірності визначення авторства Контенту, що завантажуються Замовником, Виконавець має право запросити надання правовстановлюючих документів та (-чи) фактів, які дають свідоцтво, що саме Замовник є суб’єктом, якому належать виключні права на конкретний Контент. У випадку ненадання свідоцтв, викладених в даному пункті, протягом 2-х календарних днів даний Контент буде видалений з Сайту.</li>
        </ul>
        <p>7.5. Виконавець зобов’язаний:</p>
        <ul>
          <li>Виконувати умови даного Договору;</li>
          <li>Надати Замовнику Послуги належної якості;</li>
          <li>Об’єктивно інформувати Замовника про Послуги та умови їх надання на Веб-сайті https://shusobi.com/;</li>
          <li>надавати Замовнику он-лайн підтримку через електронну пошту чи інший канал зв'язку на вибір Виконавця;</li>
          <li>Зберігати інформацію і відомості (в тому числі персональні дані), отримані від Замовника на виконання умов цього Договору, з дотримання вимог чинного законодавства України;</li>
        </ul>
        <h5>8. ВІДПОВІДАЛЬНІСТЬ СТОРІН ТА ВИРІШЕННЯ СПОРІВ</h5>
        <p>8.1. За невиконання або неналежне виконання своїх зобов’язань за цим Договором, сторони несуть відповідальність відповідно до чинного законодавства України.</p>
        <p>8.2. Усі спори, що виникають з цього Договору або пов'язані із ним, вирішуються шляхом переговорів між Сторонами.</p>
        <p>8.3. Якщо відповідний спір не можливо вирішити шляхом переговорів, він вирішується в судовому порядку за встановленою підвідомчістю та підсудністю такого спору відповідно до чинного в Україні законодавства.</p>
        <p>8.4. Виконавець не несе будь-яку відповідальність за ненадання або неналежне надання Послуг Замовнику за умови настання будь-яких обставин, які виникли не з вини Виконавця (а саме - настання обставин, які виникли з вини чи необережності Замовника та/або настання обставин, які виникли з вини чи необережності будь-якої третьої особи/осіб та/або настання форс-мажорних обставин).</p>
        <p>8.5. Обмеження відповідальності Виконавця:</p>
        <ul>
          <li>Пройшовши процедуру реєстрації та почавши користуватися можливостями Сайту та Сервісом Сайта, Замовник розуміє, усвідомлює та приймає, що в їх роботі можуть виникнути технічні похибки та несправності, а також признає, що розробники Сайту та Виконавець не несуть матеріальної відповідальності за наслідки роботи Сайту та Сервісів Сайту, за працездатність технічних засобів Користувача при їх використанні.</li>
        </ul>
        <p>8.6. Виконавець не гарантує, що:</p>
        <ul>
          <li>Сайт та Сервіси Сайту відповідають та/або будуть відповідати потребам Замовника;</li>
          <li>Доступ до Сайту та Сервіси Сайту будуть надаватися безперервно, швидко, надійно та без помилок;</li>
          <li>Якість будь-якого Контенту, послуги Сайту та його Сервісів, інформації та інших матеріалів, отриманих з використанням Сайту та Сервісів Сайту, будуть відповідати очікування Замовника;</li>
          <li>Усю інформацію та/чи матеріали (у тому числі завантажений Контент), доступ до яких Замовник отримує після Реєстрації на Сайті, а у подальшому з використання Сервісу Сайту, Замовник може використовувати на розсуд та самостійно несе відповідальність за можливі наслідки використання вказаної інформації та/чи матеріалів, у тому числі за збиток, який це може надати комп’ютеру Замовника чи третім особам, за втрату даних чи будь-яку іншу шкоду;</li>
          <li>Виконавець не несе відповідальність за невиконання чи неналежне виконання своїх обов’язків внаслідок збоїв в роботі телекомунікацій та електромереж, дії шкідливих програм, а також недобросовісних дій третіх осіб, спрямованих на несанкціонований доступ та/чи виведення з ладу Сайту, та/чи Сервісів Сайту, та/чи апаратного комплексу Виконавця;</li>
          <li>Вміст сайту несе виключно інформаційний характер. Виконавець не несе відповідальність за неточність даних та можливий збиток, який може бути внаслідок використання інформації з Сайту.</li>
        </ul>
        <h5>9. ФОРС-МАЖОРНІ ОБСТАВИНИ</h5>
        <p>9.1. Сторони звільняються від відповідальності за невиконання або неналежне виконання зобов'язань, що передбачені даним Договором, якщо воно виникло внаслідок форс-мажорних обставин.</p>
        <p>9.2. Під форс-мажорними обставинами у даному Договорі розуміються будь-які обставини, що виникли поза волею або всупереч волі чи бажанню Сторін і яких не можна передбачити чи уникнути, включаючи: військові дії, громадські заворушення, епідемії, блокаду, землетруси, повені, пожежі, а також рішення чи приписи органів державної влади та управління держави, резидентом якої є Замовник, або держави, резидентом якої є Виконавець, внаслідок яких на Сторони (або одну із Сторін) покладатимуться додаткові обов'язки чи встановлюватимуться додаткові обмеження і які роблять неможливим подальше повне або часткове виконання Договору, а також інші дії чи події, що існують поза волею Сторін.</p>
        <p>9.3. Якщо форс-мажорні обставини триватимуть більше 3-х (трьох) місяців поспіль, то кожна із Сторін матиме право відмовитись від подальшого виконання зобов'язань за цим Договором і, в такому разі, жодна із Сторін не матиме права на відшкодування іншою Стороною можливих збитків.</p>
        <h5>10. ЗАХИСТ ПЕРСОНАЛЬНИХ ДАНИХ І КОНФІДЕНЦІЙНА ІНФОРМАЦІЯ </h5>
        <p>10.1. Погоджуючись з умовами оферти і приймаючи її умови, Замовник надає Виконавцю однозначну згоду на обробку та використання будь-яких персональних даних, які стали відомими Виконавцю в результаті надання Послуг на умовах цього Договору відповідно до законодавства України в сфері захисту персональних даних. </p>
        <p>10.2. Обробка персональних даних включає, але не обмежується, збір, реєстрацію, накопичення, зберігання, адаптування, зміну, оновлення, використання і поширення (розповсюдження, реалізацію, передачу), знеособлення, знищення персональних даних, які обробляються Виконавцем, будь-якою особою, пов'язаним з Виконавцем відносинами контролю з метою ведення бази персональних даних Замовників. </p>
        <p>10.3. Замовник зобов'язується не розголошувати і не передавати конфіденційну інформацію та комерційну таємницю Виконавця для ознайомлення та/або використання третім особам без попередньої письмової згоди Виконавця.</p>
        <h5>11. ІНШІ УМОВИ ДОГОВОРУ</h5>
        <p>11.1. Кожна Сторона гарантує іншій Стороні, що володіє необхідною дієздатністю, а рівно всіма правами і повноваженнями, необхідними і достатніми для укладання і виконання цього Договору відповідно до його умов.</p>
        <p>11.2. Одностороння зміна умов укладеного Договору Замовником чи відмова виконувати умови укладеного Договору Замовником є неприпустимою, за винятків випадків, передбачених цим Договором. Жодна зі Сторін цього Договору не має права передавати свої права та обов’язки третім особам без згоди на це другої Сторони.</p>
        <p>11.3. Виконавець підтверджує, що є платником єдиного податку (група 3) за ставкою, передбаченою Податковим кодексом України.</p>
        <p>11.4. Виконавець не несе відповідальність за зміст і правдивість інформації, що надається Замовником при оформленні Замовлення. Замовник несе відповідальність за достовірність вказаної при оформленні Замовлення інформації.</p>
        <p>11.5. Сторони зобов’язуються зберігати конфіденційну інформацію, отриману в результаті виконання цього Договору, за винятком випадків, коли це письмово санкціоновано іншою Стороною або вимагається державними органами відповідно до чинного законодавства. За розголошення конфіденційної інформації винна Сторона несе відповідальність згідно з чинним законодавством.</p>
        <p>11.6. Договір є публічним і безстроковим та діє до його припинення будь-якою зі Сторін у порядку, встановленому цим Договором або чинним законодавством, але у будь-якому випадку до моменту остаточного його виконання Сторонами. Сторони погодили, що строк дії цього Договору не може становити менше 3 (трьох) календарних місяців. Цей Договір вважається погодженим Замовником та укладеним за місцезнаходженням Виконавця з дати акцептування.</p>
        <p>11.7. Виконавець самостійно у відповідності та на виконання вимог чинного законодавства України визначає умови даного Договору та Додатків до нього, які є його невід’ємними частинами. Виконавець має право самостійно змінити та/або доповнити умови цього публічного Договору та додатків до нього, включаючи правила надання та отримання Послуг за даним Договором. При цьому Виконавець гарантує та підтверджує, що розміщена на Веб-сайті Виконавця поточна редакція тексту цього Договору та додатків до нього, включаючи правила надання та отримання Послуг за даним Договором, є дійсною.</p>
        <h5>12. РЕКВІЗИТИ ВИКОНАВЦЯ</h5>
        <p className="mb-1">Фізична особа-підприємець Тюріна Ксенія Іванівна</p>
        <p className="mb-1">Адреса: Україна, 03186, місто Київ, бульвар Чоколівський, будинок 12, квартира 34</p>
        <p className="mb-1">РНОКПП: 3356413045</p>
        <p className="mb-1">Банківські реквізити: р/р UA073052990000026004016219215 в АТ «КБ «Приватбанк»</p>
        <p className="mb-1">Платник Єдиного податку 3-ї групи по ставці 5% з 03.08.2020</p>
        <p className="mb-1">Телефон +380 63 768 4771</p>
        <p className="mb-1">E-mail shusobi@gmail.com</p>
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn color="blue-grey" onClick={closeHandler}>Закрити</MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  );
};

export default Offer;
