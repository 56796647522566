import React, { Fragment } from 'react';
import SignInModal from './../../containers/Auth/SignInModal';
import SignUpModal from './../../containers/Auth/SignUpModal';
import PasswordRestoreModal from './../../containers/Auth/PasswordRestoreModal';

export default () => (
  <Fragment>
    <SignInModal />
    <SignUpModal />
    <PasswordRestoreModal />
  </Fragment>
);
