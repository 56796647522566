import React, { useState, useRef } from 'react';
import { MDBChip } from 'mdbreact';

const clearString = (string) => string.replace(/\.|\,|\(|\)|\!|\@|\$|\#|\%|\&|\*|\"|\'|\;|\:|\?|\=|\+/g, "");

const isStringValid = (string) => ( string && string.length > 1 );

const HashtagsInput = ({
  hashtags: hashtagCollection,
  hashtagsSelected = [],
  hashtagsHandler,
  hashtagsRef = {},
}) => {
  const searchInputRef = useRef();
  const [ searchInput, setSearchInput ] = useState('');
  const [ hashtags, setHashtags ] = useState(
    hashtagsSelected.map(selectedId => hashtagCollection.find(({ id }) => id === selectedId))
  );

  hashtagsRef.reset = () => setHashtags([]);

  const closeHandler = tag => () => {
    setHashtags(hashtags.filter(el => el.tag !== tag.tag));

    if ( tag.id ) {
      hashtagsHandler(hashtagsSelected.filter(selectedId => selectedId !== tag.id));
    }
  }

  const keyPress = (event) => {
    if ( event.keyCode === 27 || event.key === 'Esc' || event.key === 'Escape' ) {
      setSearchInput('');
    } else if ( event.key === 'Enter' && searchInput ) {
      const searchTags = [];
      const searchTagsId = [];

      searchInput.split(' ').map(searchItem => {
        if ( !isStringValid(searchItem) || hashtags.some(el => el.tag === searchItem) ) return;

        const tag = hashtagCollection.find(el => el.tag === searchItem) || {
          id: null,
          tag: searchItem,
        };

        searchTags.push(tag);

        if ( tag.id ) searchTagsId.push(tag.id);
      });

      if ( searchTags.length ) setHashtags([ ...hashtags, ...searchTags ]);
      if ( searchTagsId.length ) hashtagsHandler([ ...hashtagsSelected, ...searchTagsId ]);

      setSearchInput('');
    }
  }

  const inputHandler = ({ target: { value = '' } }) => setSearchInput(clearString(value.toLowerCase()));

  const searchInputFocus = () => searchInputRef.current.focus();

  return (
    <div className="chips" onClick={searchInputFocus}>
      {hashtags.map((el, index) => (
        <MDBChip
          close
          className={el.id ? '' : 'inactive'}
          onClick={closeHandler(el)}
          key={index}
          waves
        >
          {el.tag}
        </MDBChip>
      ))}
      <input
        ref={searchInputRef}
        className="input"
        type="text"
        placeholder="Додайте хештег"
        value={searchInput}
        onKeyPress={keyPress}
        onChange={inputHandler}
      />
    </div>
  );
};

export default HashtagsInput;
