import React, { Fragment } from 'react';
import Pagination from './../Atoms/Pagination';
import Table from './../Atoms/Table';
import Card from './../Atoms/Card';
import Filters from './Filters';
import Loader from './../Atoms/Loader';
import useFilters from './../../hooks/useFilters';

import states from './states';
import types from './types';

const initialFilters = {
  type: null,
  orderBy: '-reservationId',
};

const Reservations = ({ transactionsSearch }) => {
  const { data, filters, filtersHandler, orderHandler } = useFilters({ initialFilters, fetchData: transactionsSearch });

  const rowClickHandler = postId => () => postId && window.open('/posts/'+ postId, '_blanc');

  return (
    <Card
      title="Транзакції"
      body={(
        <Fragment>
          <Filters values={filters} filtersHandler={filtersHandler} />

          <Table
            head={(
              <tr>
                <th>#</th>
                <th onClick={() => orderHandler('id')} className="th">ID</th>
                <th onClick={() => orderHandler('postId')} className="th">Модель</th>
                <th onClick={() => orderHandler('amount')} className="th">Сумма</th>
                <th onClick={() => orderHandler('type')} className="th">Тип</th>
                <th onClick={() => orderHandler('state')} className="th">Статус</th>
                <th onClick={() => orderHandler('dateCreated')} className="th">Дата</th>
              </tr>
            )}
            body={data.loading ? (<tr><td colSpan="7"><Loader /></td></tr>) : data.list.map(el => (
              <tr
                key={el.id}
                className={el.postId && 'pointer'}
                onClick={rowClickHandler(el.postId)}
              >
                <td>{el.rowIndex}</td>
                <td>{el.id}</td>
                <td>{el.postId}</td>
                <td>{el.amount}</td>
                <td>{types[el.type]}</td>
                <td>{states[el.state]}</td>
                <td>{el.dateCreated}</td>
              </tr>
            ))}
          />

          <Pagination current={filters.page} pagesCount={data.pagesTotal} setPage={page => filtersHandler({ page })} />
        </Fragment>
      )}
    />
  );
};

export default Reservations;
