import React from 'react';
import { withRouter } from 'react-router-dom';
import { MDBSelect } from 'mdbreact';

const Filters = props => {
  const selectHandle = id => values => {
    let filtersValues = { ...props.filtersValues };

    if ( filtersValues[id] === undefined && values.length === 0 ) return false;

    if ( filtersValues[id] !== undefined && values.every(el => filtersValues[id].includes(el)) && values.length === filtersValues[id].length )
      return false;

    filtersValues[id] = values;

    props.filtersSet({ filtersValues, page: 1 });

    if ( window.location.pathname !== '/' ) props.history.push('/');
  }

  const filterListToggle = event => {
    event.stopPropagation();
    event.preventDefault();

    const filterList = event.target.nextSibling.querySelector('.dropdown-content.select-dropdown');
    const filterListFaded = document.querySelector('.dropdown-content.select-dropdown.fadeElement.fadeIn');

    if ( filterList.classList.contains('fadeIn') ) {
      event.target.parentElement.classList.remove('active');
      filterList.classList.remove('fadeIn');
    } else {

      if ( filterListFaded !== null ) filterListFaded.classList.remove('fadeIn');

      event.target.parentElement.classList.add('active');
      filterList.classList.add('fadeIn');
    }
  }

  return (
    <div className="filters-wrapper">
      {props.filtersOptions.map(el => (
        <div className="filter-select-wrapper" key={el.id}>
          <div className="filter-layer-click-handler" onClick={filterListToggle}></div>
          <MDBSelect multiple label={el.label} options={el.options} getValue={selectHandle(el.id)} />
        </div>
      ))}
    </div>
  );
}

export default withRouter(Filters);
