import React from 'react';
import PropTypes from 'prop-types';
import { MDBBtn, MDBIcon } from 'mdbreact';

const Button = ({ title, color, type, size, form, icon, outline, onClick }) => (
  <MDBBtn
    color={color}
    type={type}
    form={form}
    onClick={onClick}
    size={size}
    outline={outline}
  >
    {icon && (<MDBIcon className="mr-2" icon={icon} />)}
    {title}
  </MDBBtn>
);

Button.defaultProps = {
  color: 'primary',
  type: 'button',
  size: 'md',
  form: null,
  icon: null,
  outline: false,
  onClick: () => {}
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  form: PropTypes.string,
  icon: PropTypes.string,
  outline: PropTypes.bool,
  onClick: PropTypes.func
};

export default Button;
