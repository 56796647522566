import React from 'react';
import Tile from './../../containers/Posts/Tile';

const Tiles = ({ list }) => {
  return (
    <div className="posts-list">
      {list.map(el => (
        <Tile key={el.id} {...el} />
      ))}
    </div>
  );
}

export default Tiles;
