import * as types from './../constants/ActionTypesContacts';
import axiosDispatch from './../axios/dispatch';
import { notification } from './App';

export function create(params) {
  return dispatch => {
    return axiosDispatch(dispatch, '/contacts/create', params)
      .then(data => notification('success', 'Лист відправлено!'))
      .catch(error => {
        notification('error', error);
        throw error;
      });
  }
}

export function createModalToggle(params)
{
  return {
    type: types.CONTACTS_CREATE_MODAL_TOGGLE,
    payload: params
  }
}
