import React from 'react';
import { MDBIcon } from 'mdbreact';
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBBtn
} from 'mdbreact';

const Payment = ({ isOpen, closeHandler }) => {
  return (
    <MDBModal isOpen={isOpen} toggle={closeHandler} centered>
      <MDBModalHeader>
        <MDBIcon fas icon="credit-card" style={{ marginRight: '20px' }} />
        Про оплату
      </MDBModalHeader>
      <MDBModalBody className="mb-0 modal-lg" style={{ textAlign: 'justify' }}>
        <p>Ми працюємо з надійними, захищеними системами обробки платежів. Приймаються картки будь-яких банків світу.</p>
        <p>Інформація обробляється з використанням SSL 256 шифрування на сервері системи електронних платежів (кодується вся особиста інформація, в тому числі дані кредитних і дебетових пластикових карт, ПІБ і адреси). Це означає, що дані вашої карти недоступні ні адміністраторам нашого сайту, ні команді Shusobi.com, ні кому-небудь ще.</p>
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn color="blue-grey" onClick={closeHandler}>Закрити</MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  );
};

export default Payment;
