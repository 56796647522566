import React, { useState, useEffect, Fragment } from 'react';
import useForm from './../../hooks/useForm';
import { MDBBtn, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import Input from './../Atoms/Input';
import validation from './validationPayIn';
import Loader from './../Atoms/Loader';

const initialValues = { amount: 50 };

const PayInModal = (props) => {
  const closeHandler = () => {
    props.payInModalToggle({ isOpen: false });
    setSubmitting(false);
    setError(null);
  };

  const submitHandler = event => {
    props.checkoutForm({ ...values, redirectUrl: window.location.href })
      .then(data => setDynamicForm(data.checkoutForm))
      .catch(error => setError(error));
  };

  const inputHandler = event => setValue(event.target.name, event.target.value);

  const { values, setValue, errors, onSubmit, submitting, setSubmitting } = useForm({ initialValues, validation, submitHandler });
  const [ dynamicForm, setDynamicForm ] = useState(null);
  const [ error, setError ] = useState(null);

  if ( props.values.amount && props.values.amount !== values.amount ) setValue('amount', props.values.amount);

  useEffect(() => {
    const form = document.querySelector('#payment-dynamic-form form');

    if ( form ) form.submit();
  }, [ dynamicForm ]);

  return (
    <MDBModal isOpen={props.isOpen} toggle={closeHandler} centered>
      <MDBModalHeader toggle={closeHandler}>
        Поповнення рахунку
      </MDBModalHeader>
      <MDBModalBody>
        {props.buyText && (
          <Fragment>
            <p>Ви збираєтесь завантажити модель вартістю {values.amount} гривень.</p>
            <p>Будьте ласкаві, поповніть Ваш рахунок перед завантаженням.</p>
            <p>Після завантаження, модель буде додана на зберігання в Ваш особистий кабінет та доступна до повторного, безкоштовного завантаження.</p>
            <p>Дякуємо за довіру!</p>
          </Fragment>
        )}
        <Input label="Введіть сумму поповнення" name="amount" type="text"
          value={''+ values.amount} error={errors.amount} onChange={inputHandler}
        />
        {submitting && <Loader />}
        <p className="text-danger">{error}</p>
        {dynamicForm && (
          <div
            id="payment-dynamic-form"
            dangerouslySetInnerHTML={{__html: dynamicForm}}
            style={{ display: 'none' }}
          ></div>
        )}
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn color="blue-grey" disabled={submitting} onClick={closeHandler}>Скасувати</MDBBtn>
        <MDBBtn color="grey" disabled={submitting} onClick={onSubmit}>Поповнити</MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  );
};

export default PayInModal;
