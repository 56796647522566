import * as types from './../constants/ActionTypesColors';
import axiosDispatch from './../axios/dispatch';

export function getList() {
  return dispatch => {
    axiosDispatch(dispatch, '/content/colors')
      .then(data => dispatch(fetchList(data)));
  }
}

export function fetchList(data) {
  return {
    type: types.COLORS_FETCH,
    payload: data
  }
}
