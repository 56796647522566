import React from 'react';
import styled from 'styled-components';
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBBtn
} from 'mdbreact';

const Link = styled('span')`
  color: #788f9c;
  cursor: pointer;
  text-decoration: underline;
`;

const Cooperation = ({ isOpen, closeHandler, postsCreate }) => {
  const onCreate = () => {
    closeHandler();
    postsCreate({ isOpen: true });
  };

  return (
    <MDBModal isOpen={isOpen} toggle={closeHandler} centered>
      <MDBModalHeader>
        Співробітницство
      </MDBModalHeader>
      <MDBModalBody className="mb-0 modal-lg" style={{ textAlign: 'justify' }}>
        <p>«Шукаю собі» - відкрита для співпраці з магазинами, та творчими особистостями. Якщо в Вас є бажання розмістити Ваш товар на інформаційній платформі, Ви маєте змогу заповнити <Link onClick={onCreate}>форму на нову модель</Link>. Та найближчим часом ми ознайомимось з товаром та зв’яжимось з Вами.</p>
        <p>Чи надіслати Ваш товар на електронну адресу shusobi@gmail.com.</p>
        <p>Вимоги до товару:</p>
        <ul>
        <li>Ви повинні бути автором товару.</li>
        <li>Предоставити характеристику товару, матеріали, розміри, вагу тощо.</li>
        <li>При наявності 3д модель об’экту.</li>
        </ul>
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn color="blue-grey" onClick={closeHandler}>Закрити</MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  );
};

export default Cooperation;
