import React, { PureComponent, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { MDBCard, MDBCardBody, MDBIcon } from 'mdbreact';
import DownloadModal from './DownloadModal';
import Slider from './../Slider';

const FileBtn = ({ onClick, price }) => (
  <a className="file-link" onClick={onClick}>
    <span className="short"><MDBIcon icon="download" /> 3D</span>
    <span className="full">Завантажити 3D {price ? ` - ${price}₴` : null}</span>
  </a>
);

class View extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { id: props.match.params.id, data: null };
    this.getItem(props.match.params.id);
    this.modelBuyConfirmation = React.createRef();
  }

  getItem = id => {
    this.props.getItem(id)
      .then(data => this.setState({ id: id, data: data }));
  }

  favoriteAdd = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.favoritesAdd(id);

    let data = { ...this.state.data };
    data.isFavorite = true;
    this.setState({ data });
  }

  favoriteRemove = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.favoritesRemove(id);

    let data = { ...this.state.data };
    data.isFavorite = false;
    this.setState({ data });
  }

  signIn = event => {
    event.preventDefault();
    this.props.signIn('signIn');
  }

  archiveGetLink = () => {
    this.props.getArchiveLink(this.state.id)
      .then(data => window.open(data.link, '_blanc'));
    this.props.getBalance();
  }

  archiveDownload = () => {
    const { priceDownload, isPostPurcahsed } = this.state.data;

    if ( !priceDownload || isPostPurcahsed ) {
      this.archiveGetLink();
    } else if ( this.props.balance < priceDownload ) {
      this.props.payInModalToggle({ isOpen: true, values: { amount: priceDownload }, buyText: true });
    } else {
      this.modelBuyConfirmation.current.open();
    }
  }

  render() {
    const { colors, auth } = this.props;
    const { data } = this.state;

    if ( data === null ) return null;

    return (
      <div className={`post-page-wrapper bgc-f3 ${data.isFavorite ? 'favorite' : ''}`}>
        <div className="column-left go-home" onClick={() => this.props.history.goBack()}>
          <p><MDBIcon icon="chevron-left" /> Home</p>
        </div>
        <div className="column-middle">
          <MDBCard className="post-page">
            <MDBCardBody>
              <div className="content">
                <div className="images-wrapper">
                  <Slider files={data.files} />
                  <div className="favorite-btn">
                    { ! auth
                      ? (<MDBIcon far icon="heart" onClick={this.signIn} />)
                      : data.isFavorite
                        ? (<MDBIcon far={false} icon="heart" onClick={e => this.favoriteRemove(e, data.id)} />)
                        : (<MDBIcon far icon="heart" onClick={e => this.favoriteAdd(e, data.id)} />)
                    }
                  </div>
                </div>
                <div className="info-wrapper">
                  <div className="shop">
                    <img className="logo" src={data.shop.src} alt={data.shop.title} />
                    <span className="title">{data.shop.title}</span>
                  </div>
                  <h1 className="title">{data.title}</h1>
                  <p className="price">{data.priceShop}</p>
                  <h5 className="characteristic-title">
                    Характеристика
                    {data.youtube === null ? null : (
                      <a className="youtube-link" href={data.youtube} target="_blanc"><MDBIcon fab icon="youtube" /></a>
                    )}
                  </h5>
                  <dl className="row characteristic-items">
                    {data.characteristics.map(el => (
                      <Fragment key={el.id}>
                        <dt className="col-4 col-sm-3">{el.key}</dt>
                        <dd className="col-6 col-sm-7">{el.value}</dd>
                        <dd className="col-2">
                          {el.colors.map(el => (
                            <img className="color-item rounded-circle" src={el.src} alt={el.title} key={el.id} />
                          ))}
                        </dd>
                      </Fragment>
                    ))}
                  </dl>
                  {data.colors.map(id => colors[id] === undefined ? null : (
                    <img className="color-item rounded-circle" src={colors[id].src} alt={colors[id].title} key={id} />
                  ))}
                  <div className="action-buttons">
                    {data.instagram === null ? null : (
                      <a className="grey-btn" href={data.instagram} target="_blanc"><MDBIcon fab icon="instagram" /></a>
                    )}
                    {data.link === null ? null : (
                      <a className="grey-btn shop-link" href={data.link} target="_blanc">До магазину</a>
                    )}
                    {data.file === null ? null : auth
                      ? (<FileBtn onClick={this.archiveDownload} price={!data.isPostPurcahsed && data.priceDownload} />)
                      : (<FileBtn onClick={this.signIn} />)
                    }
                  </div>
                </div>
              </div>
            </MDBCardBody>
          </MDBCard>
        </div>
        <div className="column-right"></div>
        <DownloadModal modalRef={this.modelBuyConfirmation} price={data.priceDownload} onSuccess={this.archiveGetLink} />
      </div>
    );
  }
}

export default withRouter(View);
