import * as types from './../constants/ActionTypesUsers';

const initialState = {
  balance: 0,
  data: {},
};

export default (state = initialState, action) => {
  switch ( action.type ) {

    case types.USER_FETCH_DATA:
      return { ...state, data: { ...action.payload } };

    case types.USER_CLEAR:
      return { ...initialState };

    case types.USER_BALANCE_FETCHED:
      return { ...state, balance: action.payload.balance };

    default:
      return state;
  }
};
