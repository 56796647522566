import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import SelectLimit from './../Atoms/Select/Limit';
import Select from './../Atoms/Select';
import typesOption from './typesOption';

const Filters = props => {
  const onSelect = key => val => {
    if ( props.values[key] !== val ) {
      props.filtersHandler({ [key]: val, page: 1 });
    }
  };

  return (
    <div className="table__filters">
      <MDBRow>
        <MDBCol xs="12" sm="6" md="3">
          <Select options={typesOption(props.values.type)} onSelect={onSelect('type')} label="Тип" />
        </MDBCol>
        <MDBCol xs="12" sm="6" md="3">
        </MDBCol>
        <MDBCol xs="12" sm="6" md="3">
        </MDBCol>
        <MDBCol xs="12" sm="6" md="3">
          <SelectLimit selectedValue={props.values.limit} onSelect={onSelect('limit')} />
        </MDBCol>
      </MDBRow>
    </div>
  );
}

Filters.propTypes = {};

export default Filters;
