import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Model = ({ alt, android, className, ios, poster }) => {
  return (
    <model-viewer
      alt={alt}
      ar
      autoplay
      camera-controls
      class={className}
      ios-src={ios}
      loading="lazy"
      poster={poster}
      src={android}
    />
  );
};

Model.defaultProps = {
  alt: 'Model3D',
  poster: null,
};

Model.propTypes = {
  alt: PropTypes.string,
  android: PropTypes.string.isRequired,
  ios: PropTypes.string.isRequired,
  poster: PropTypes.string,
};

export default styled(Model)`
  height: 100%;
  width: 100%;
`;
