import React, { PureComponent } from 'react';
import { MDBBtn, MDBModal, MDBModalHeader, MDBModalFooter, MDBCard, MDBCardBody, MDBInput } from 'mdbreact';

class Modal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { email: '', password: '', error: '' };
  }

  inputHandle = event => {
    this.setState({ [event.target.name]: event.target.value, error: '' });
  }

  submitHandler = event => {
    event.preventDefault();
    this.props.signIn({ email: this.state.email, password: this.state.password })
      .catch(error => this.setState({ error }));
  }

  render() {
    const { email, password, error } = this.state;

    return (
      <MDBModal isOpen={this.props.modal === 'signIn'} toggle={this.props.modalClose} centered>
        <MDBModalHeader className="text-center" titleClass="w-100 font-weight-bold my-3" toggle={this.props.modalClose}>Авторизація</MDBModalHeader>
        <MDBCard>
          <MDBCardBody className="mx-4">
            <form onSubmit={this.submitHandler}>
              <MDBInput label="Ваш email" name="email" group type="email"
                value={email} onChange={this.inputHandle}
              >
                <div className="invalid-feedback">Provide a valid last name!</div>
              </MDBInput>
              <MDBInput label="Ваш пароль" name="password" group type="password" containerClass="mb-0"
                value={password} onChange={this.inputHandle}
              />
              <p className="text-danger">{error}</p>
              <p className="font-small grey-text d-flex justify-content-end pb-3">
                Забули
                <a href="#!" className="dark-grey-text ml-1" onClick={() => this.props.modalOpen('passwordRestore')}>Пароль?</a>
              </p>
              <div className="text-center mb-3">
                <MDBBtn type="submit" rounded className="btn-block btn-grey z-depth-1a">АВТОРИЗУВАТИСЯ</MDBBtn>
              </div>
            </form>
            {/*<p className="font-small dark-grey-text text-right d-flex justify-content-center mb-3 pt-2">or Sign in with:</p>
            <div className="row my-3 d-flex justify-content-center">
              <MDBBtn type="button" color="white" rounded className="mr-md-3 z-depth-1a">
                <MDBIcon fab icon="facebook-f" className="blue-text text-center" />
              </MDBBtn>
              <MDBBtn type="button" color="white" rounded className="mr-md-3 z-depth-1a">
                <MDBIcon fab icon="twitter" className="blue-text" />
              </MDBBtn>
              <MDBBtn type="button" color="white" rounded className="z-depth-1a">
                <MDBIcon fab icon="google-plus-g" className="blue-text" />
              </MDBBtn>
            </div>*/}
          </MDBCardBody>
          <MDBModalFooter className="mx-5 pt-3 mb-1">
            <p className="font-small grey-text d-flex justify-content-end">
              Немає аккаунту?
              <a href="#" className="dark-grey-text ml-1" onClick={() => this.props.modalOpen('signUp')}>
                Зареєструватися!
              </a>
            </p>
          </MDBModalFooter>
        </MDBCard>
      </MDBModal>
    );
  }
}

export default Modal;
