import React, { PureComponent } from 'react';
import { MDBBtn, MDBModal, MDBModalHeader, MDBModalFooter, MDBCard, MDBCardBody, MDBInput } from 'mdbreact';

class Modal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { email: '', error: '', successMsg: false };
  }

  inputHandle = event => {
    this.setState({ [event.target.name]: event.target.value, error: '' });
  }

  submitHandler = event => {
    event.preventDefault();
    this.props.passwordRestore({ email: this.state.email })
      .then(() => this.setState({ successMsg: true }))
      .catch(error => this.setState({ error }));
  }

  render() {
    const { email, error, successMsg } = this.state;

    return (
      <MDBModal isOpen={this.props.modal === 'passwordRestore'} toggle={this.props.modalClose} centered>
        <MDBModalHeader className="text-center" titleClass="w-100 font-weight-bold my-3" toggle={this.props.modalClose}>Відновити пароль</MDBModalHeader>
        <MDBCard>
          {successMsg ? (
            <MDBCardBody className="mx-4">
              <p className="mt-2 mb-4 text-center">Новий пароль відправлено Вам на поштову адресу.</p>
              <div className="text-center mb-3">
                <MDBBtn type="button" rounded className="btn-block btn-grey z-depth-1a" onClick={() => this.props.modalOpen('signIn')}>АВТОРИЗУВАТИСЯ</MDBBtn>
              </div>
            </MDBCardBody>
          ) : (
            <MDBCardBody className="mx-4">
              <form onSubmit={this.submitHandler}>
                <MDBInput label="Ваш email" name="email" group type="email"
                  value={email} onChange={this.inputHandle}
                />
                <p className="text-danger">{error}</p>
                <div className="text-center mb-3">
                  <MDBBtn type="submit" rounded className="btn-block btn-grey z-depth-1a">ВІДНОВИТИ</MDBBtn>
                </div>
              </form>
            </MDBCardBody>
          )}
          <MDBModalFooter className="mx-5 pt-3 mb-1">
            <p className="font-small grey-text d-flex justify-content-end">
              Вже є аккаунт?
              <a href="#" className="dark-grey-text ml-1" onClick={() => this.props.modalOpen('signIn')}>
                Авторизуватися!
              </a>
            </p>
          </MDBModalFooter>
        </MDBCard>
      </MDBModal>
    );
  }
}

export default Modal;
