import React from 'react';
import { Link } from 'react-router-dom';
import { MDBSideNavNav, MDBSideNav } from 'mdbreact';
import './index.scss';

const NavbarLeft = (props) => {
  return (
    <MDBSideNav
      logo="/img/logo.png"
      triggerOpening={props.navbarLeftOpen}
      breakWidth={props.breakWidth}
      className="cabinet-nav"
      mask="strong"
      fixed
      href="/"
    >
      <MDBSideNavNav>
        <li>
          <Link to="/cabinet/profile">Профіль</Link>
        </li>
        <li>
          <Link to="/cabinet/posts">Моделі</Link>
        </li>
        <li>
          <Link to="/cabinet/transactions">Транзакції</Link>
        </li>
      </MDBSideNavNav>
    </MDBSideNav>
  );
};

export default NavbarLeft;
