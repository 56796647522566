import React, { PureComponent } from 'react';
import {
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBBtn
} from 'mdbreact';

class Create extends PureComponent
{
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      title: '',
      description: ''
    };
  }

  componentDidUpdate(prevProps) {
    if ( this.props.user.email !== prevProps.user.email ) {
      this.setState({ email: this.props.user.email });
    }
  }

  inputHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  submitHandler = event => {
    event.preventDefault();

    const params = {
      email: this.state.email,
      title: this.state.title,
      description: this.state.description
    };

    this.props.create(params)
      .then(data => this.closeHandler());
  }

  closeHandler = () => {
    this.props.createModalToggle({ isOpen: false });
  }

  render() {
    const { email, title, description } = this.state;

    return (
      <MDBModal isOpen={this.props.isOpen} toggle={this.closeHandler} centered>
        <form onSubmit={this.submitHandler}>
          <MDBModalBody className="mb-0 modal-lg">
            <MDBInput label="Email" name="email" value={email} onChange={this.inputHandler} required />
            <MDBInput label="Тема" name="title" value={title} onChange={this.inputHandler} required />
            <MDBInput type="textarea" label="Опишіть Вашу ситуацію" name="description" rows="3" value={description} onChange={this.inputHandler} required />
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="blue-grey" onClick={this.closeHandler}>Скасувати</MDBBtn>
            <MDBBtn color="grey" type="submit">Надіслати</MDBBtn>
          </MDBModalFooter>
        </form>
      </MDBModal>
    );
  }
}

export default Create;
