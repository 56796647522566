import React, { useState } from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalFooter } from 'mdbreact';

const PayInModal = (props) => {
  const [ isOpen, setOpen ] = useState(false);

  const closeHandler = () => setOpen(false);

  const onSuccess = () => {
    props.onSuccess();
    closeHandler();
  };

  props.modalRef.current = { open: () => setOpen(true) };

  return (
    <MDBModal isOpen={isOpen} toggle={closeHandler} centered>
      <MDBModalBody>
        <p>Ви збираєтесь завантажити модель вартістю {props.price} гривень.</p>
        <p>Після завантаження, модель буде додана на зберігання в Ваш особистий кабінет та доступна до повторного, безкоштовного завантаження.</p>
        <p>Дякуємо за довіру!</p>
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn color="blue-grey" onClick={closeHandler}>Скасувати</MDBBtn>
        <MDBBtn color="grey" onClick={onSuccess}>Завантажити</MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  );
};

export default PayInModal;
