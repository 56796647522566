import React, { useState, Fragment } from 'react';
import useForm from './../../hooks/useForm';
import validation from './validation';
import { MDBBtn, MDBFileInput } from 'mdbreact';
import Modal from './../Atoms/Modal';
import Input from './../Atoms/Input';

const CreateModal = props => {
  const closeHandler = () => props.createModalToggle({ isOpen: false });

  const inputHandler = event => setValue(event.target.name, event.target.value);

  const submitHandler = event => props.create(values).then(() => closeHandler());

  const filesHandler = fileList => {
    const reader = new FileReader();
    const file = fileList[0];

    if ( !file ) return;
    if ( file.size > 250000000 ) return setFileError(true);
    if ( fileError ) setFileError(false);

    reader.onloadend = () => setValue('file', { src: reader.result, name: file.name });
    reader.readAsDataURL(file);
  }

  const { values, errors, setValue, onSubmit } = useForm({ validation, submitHandler });

  const [ fileError, setFileError ] = useState(false);

  return (
    <Modal
      isOpen={props.isOpen}
      closeHandler={closeHandler}
      body={
        <form>
          <Input label="Посилання на Виробника" name="link" value={values.link} onChange={inputHandler} />
          <Input label="Youtube" name="youtube" value={values.youtube} onChange={inputHandler} />
          <Input label="Instagram" name="instagram" value={values.instagram} onChange={inputHandler} />
          <MDBFileInput getValue={filesHandler} btnTitle="Оберіть файл" textFieldTitle="Завантажити файл" btnColor="grey" />
          <p className={( fileError ? 'red-text' : 'grey-text' ) +' font-small d-flex justify-content-center'}>
            Максимальний розмір завантаження не більше 200 Мб
          </p>
          {errors.form && (<p className="red-text font-small d-flex justify-content-center">{errors.form}</p>)}
        </form>
      }
      footer={
        <Fragment>
          <MDBBtn color="blue-grey" onClick={closeHandler}>Скасувати</MDBBtn>
          <MDBBtn color="grey" onClick={onSubmit}>Надіслати</MDBBtn>
        </Fragment>
      }
    />
  );
};

export default CreateModal;
