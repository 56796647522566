import styled from 'styled-components';
import PropTypes from 'prop-types';

const Square = styled('div')`
  border: solid ${({ borderWidth }) => borderWidth} #4f4c4d;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`;

Square.defaultProps = {
  borderWidth: '1px',
  size: '15px',
};

Square.propTypes = {
  borderWidth: PropTypes.string,
  size: PropTypes.string,
};

export default Square;
