import React from 'react';
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBBtn
} from 'mdbreact';

const AboutUs = ({ isOpen, closeHandler }) => {
  return (
    <MDBModal isOpen={isOpen} toggle={closeHandler} centered>
      <MDBModalHeader>Про нас</MDBModalHeader>
      <MDBModalBody className="mb-0 modal-lg" style={{ textAlign: 'justify' }}>
        <h5>Ми створили інформаційну платформу</h5>
        <p>«Shusobi», щоб заощадити майже один з найдорожчих ресурсів в світі - ЧАС. Цей сайт дозволяє електронним майданчикам магазинів і творчим особистостям розміщувати свою продукцію, а користувачам знайти предмети інтер'єру або декору на свій смак.</p>
        <p>Це ж так круто, коли вироби знаходить свого власника, а творці створюють щось особливе, про що потім кажуть: «Це саме те, що я шукаю!»</p>
        <p>Наша мета - зробити все можливе, щоб не виходячи з дому Ви змогли підібрати собі предмет за формою, кольором або призначенням. Крім того, головним атрибутом при розміщенні об'єктів на сайті є посилання на ресурс-першоджерело, де можна купити товар на території України. Не має значення, чи є власник продукції великим меблевим виробництвом, або це виготовляється у власній домашній майстерні. Ми за творчість, за українське, і можливість реалізовуватися. Тому бажаємо Вам з користю і задоволенням провести час на нашій платформі!</p>
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn color="blue-grey" onClick={closeHandler}>Закрити</MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  );
};

export default AboutUs;
