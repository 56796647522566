import * as types from './../constants/ActionTypesPosts';
import axiosDispatch from './../axios/dispatch';

export function getList(params = {}) {
  return dispatch => {
    dispatch(loading(true));
    axiosDispatch(dispatch, '/content/posts', params)
      .then(data => dispatch(fetchList(data)))
      .catch(error => dispatch(loading(false)));
  }
}

export function search(params = {}) {
  return dispatch => {
    return axiosDispatch(dispatch, '/content/posts', params);
  }
}

export function getItem(id) {
  return dispatch => {
    return axiosDispatch(dispatch, '/content/post', { id })
  }
}

export function create(params) {
  return dispatch => {
    return axiosDispatch(dispatch, '/posts/createOffer', params);
  }
}

export function filtersSet(params) {
  return (dispatch, getState) => {
    const filters = { ...getState().postsFilters, ...params };

    if ( filters.page === 1 ) dispatch(clearList());

    dispatch(getList(filters));
    dispatch({
      type: types.POSTS_FILTERS_SET,
      payload: filters
    });
  }
}

export function nextPage() {
  return (dispatch, getState) => {
    const page = getState().postsFilters.page + 1;

    dispatch(filtersSet({ page }));
  }
}

export function favoritesAdd(id) {
  return dispatch => {
    dispatch({
      type: types.POSTS_FAVORITES_ADD,
      payload: { id }
    });
    axiosDispatch(dispatch, '/favorites/add', { id });
  }
}

export function favoritesRemove(id) {
  return dispatch => {
    dispatch({
      type: types.POSTS_FAVORITES_REMOVE,
      payload: { id }
    });
    axiosDispatch(dispatch, '/favorites/remove', { id });
  }
}

export function getArchiveLink(postId) {
  return dispatch => {
    return axiosDispatch(dispatch, '/files/linkToDownload', { postId });
  }
}

export function createModalToggle(params) {
  return {
    type: types.POSTS_CREATE_MODAL_TOGGLE,
    payload: params
  }
}

export function loading(state) {
  return {
    type: types.POSTS_LOADING,
    payload: state
  }
}

export function fetchList(data) {
  return {
    type: types.POSTS_FETCHED,
    payload: data
  }
}

export function clearList() {
  return {
    type: types.POSTS_CLEAR
  }
}
