import * as types from './../constants/ActionTypesAuth';
import axiosDispatch from './../axios/dispatch';
import { fetchData as userFetchData, clear as userClear } from './Users';

export function signIn(params) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axiosDispatch(dispatch, '/auth/signIn', params)
        .then(data => {
          dispatch(authSuccess(data.token));
          dispatch(userFetchData(data.user));
        })
        .catch(error => reject(error));
    });
  }
}

export function signUp(params) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axiosDispatch(dispatch, '/auth/signUp', params)
        .then(data => {
          dispatch(authSuccess(data.token));
          dispatch(userFetchData(data.user));
        })
        .catch(error => reject(error));
    });
  }
}

export function passwordRestore(params) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axiosDispatch(dispatch, '/auth/passwordRestore', params)
        .then(data => resolve())
        .catch(error => reject(error));
    });
  }
}

export function authSuccess(jwt) {
  localStorage.setItem('jwt', jwt);

  return {
    type: types.AUTH_SUCCESS,
    payload: { jwt }
  }
}

export function signOut() {
  return dispatch => {
    localStorage.removeItem('jwt');

    dispatch({ type: types.SIGN_OUT });
    dispatch(userClear());
  }
}

export function modalOpen(modal) {
  return {
    type: types.AUTH_MODAL_OPEN,
    payload: { modal }
  };
}

export function modalClose() {
  return { type: types.AUTH_MODAL_CLOSE };
}
