import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Auth from './../containers/Auth';
import Posts from './../containers/Posts';
import Post from './../containers/Posts/View';
import Content from './../components/Content';
import Cabinet from './../components/Cabinet';

export default () => (
  <Switch>
    <Route exact path="/" component={() => (<Content child={<Posts />} />)} />
    <Route exact path="/posts/:id" component={() => (<Content child={<Post />} />)} />
    <Auth>
      <Route path="/cabinet" component={Cabinet} />
    </Auth>
  </Switch>
);
