import { combineReducers } from 'redux';
import postsFilters from './postsFilters';
import hashtags from './hashtags';
import filters from './filters';
import colors from './colors';
import posts from './posts';
import user from './user';
import auth from './auth';
import modals from './modals';

export default combineReducers({
  postsFilters,
  hashtags,
  filters,
  colors,
  posts,
  user,
  auth,
  modals
});
