import React, { PureComponent } from 'react';
import { MDBBtn, MDBModal, MDBModalHeader, MDBModalFooter, MDBCard, MDBCardBody, MDBInput } from 'mdbreact';

class Modal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { email: '', profession: '', password: '', error: '' };
  }

  inputHandle = event => {
    this.setState({ [event.target.name]: event.target.value, error: '' });
  }

  submitHandler = event => {
    event.preventDefault();

    const params = {
      email: this.state.email,
      profession: this.state.profession,
      password: this.state.password
    };

    this.props.signUp(params)
      .catch(error => this.setState({ error }));
  }

  render() {
    const { email, profession, password, error } = this.state;

    return (
      <MDBModal isOpen={this.props.modal === 'signUp'} toggle={this.props.modalClose} centered>
        <MDBModalHeader className="text-center" titleClass="w-100 font-weight-bold my-3" toggle={this.props.modalClose}>Реєстрація</MDBModalHeader>
        <MDBCard>
          <MDBCardBody className="mx-4">
            <form onSubmit={this.submitHandler}>
              <MDBInput label="Ваш email" name="email" group type="email"
                value={email} onChange={this.inputHandle}
              />
              <MDBInput label="Вид деятельности" name="profession" group type="text"
                value={profession} onChange={this.inputHandle}
              />
              <MDBInput label="Ваш пароль" name="password" group type="password"
                value={password} onChange={this.inputHandle}
              />
              <p className="text-danger">{error}</p>
              <div className="text-center mb-3">
                <MDBBtn type="submit" rounded className="btn-block btn-grey z-depth-1a">ЗАРЕЄСТРУВАТИСЯ</MDBBtn>
              </div>
            </form>
          </MDBCardBody>
          <MDBModalFooter className="mx-5 pt-3 mb-1">
            <p className="font-small grey-text d-flex justify-content-end">
              Вже є аккаунт?
              <a href="#" className="dark-grey-text ml-1" onClick={() => this.props.modalOpen('signIn')}>
                Авторизуватися!
              </a>
            </p>
          </MDBModalFooter>
        </MDBCard>
      </MDBModal>
    );
  }
}

export default Modal;
