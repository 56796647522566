import { connect } from 'react-redux';
import { favoritesAdd, favoritesRemove } from './../../actions/Posts';
import { modalOpen as signIn } from './../../actions/Auth';
import Tile from './../../components/Posts/Tile';

const mapState = (state, props) => ({
  isAuthorized: state.auth.jwt !== null,
});

export default connect(mapState, { favoritesAdd, favoritesRemove, signIn })(Tile);
