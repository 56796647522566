import * as types from './../constants/ActionTypesFooter';

export function toggle() {
  return (dispatch, getState) => {
    dispatch({
      type: types.FOOTER_TOGGLE,
      payload: { isOpen: !getState().modals.footer.isOpen },
    });
  };
}
