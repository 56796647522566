import React, { Fragment } from 'react';
import useForm from './../../hooks/useForm';
import validation from './validation';
import Input from './../Atoms/Input';
import Card from './../Atoms/Card';
import { MDBBtn } from 'mdbreact';

const Profile = props => {
  const inputHandler = event => setValue(event.target.name, event.target.value);

  const submitHandler = event => props.update(values);

  const { values, setValue, errors, onSubmit } = useForm({ initialValues: props.data, validation, submitHandler });

  return (
    <Card
      title="Особисті дані"
      body={
        <Fragment>
          <Input label="Ваш email" name="email" type="email"
            value={values.email} error={errors.email} onChange={inputHandler}
          />
          <Input label="Вид діяльності" name="profession" type="text"
            value={values.profession} error={errors.profession} onChange={inputHandler}
          />
          <Input label="Ваш пароль" name="password" type="password"
            value={values.password} error={errors.password} onChange={inputHandler}
          />
          <Input label="Ваш новий пароль" name="newPassword" type="password"
            value={values.newPassword} error={errors.newPassword} onChange={inputHandler}
          />
          <MDBBtn color="grey" onClick={onSubmit}>Зберегти</MDBBtn>
        </Fragment>
      }
    />
  );
};

export default Profile;
