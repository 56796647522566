import { connect } from 'react-redux';
import { modalOpen } from './../../actions/Auth';
import { getList, filtersSet } from './../../actions/Posts';
import Navigation from './../../components/Navigation/Top';

const mapStateToProps = state => ({
  favorites: state.postsFilters.favorites,
  hashtagsSelected: state.postsFilters.hashtags,
  authorized: state.auth.jwt !== null,
  hashtags: state.hashtags
});

export default connect(mapStateToProps, { getList, filtersSet, modalOpen })(Navigation);
