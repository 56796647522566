import React from 'react';
import PropTypes from 'prop-types';
import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact';
import Button from './../Button';
import './index.scss';

const Card = ({ title, actions, body }) => (
  <MDBCard narrow>
    <MDBCardHeader className="custom-card-header">
      <h2>{title}</h2>
      <div>
        {actions.map((el, i) => (<Button key={i} {...el} />))}
      </div>
    </MDBCardHeader>
    <MDBCardBody>
      {body}
    </MDBCardBody>
  </MDBCard>
);

Card.defaultProps = {
  actions: []
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.any.isRequired,
  actions: PropTypes.array
};

export default Card;
