import React, { Fragment } from 'react';
import Navigation from './../containers/Navigation/Top';

const Content = ({ child }) => (
  <Fragment>
    <Navigation />
    {child}
  </Fragment>
);

export default Content;
