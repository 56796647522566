import { connect } from 'react-redux';
import { signOut } from './../../actions/Auth';
import { payInModalToggle } from './../../actions/Balance';
import Navigation from './../../components/Navigation/Cabinet';

const mapState = (state) => ({
  balance: state.user.balance
});

export default connect(mapState, { signOut, payInModalToggle })(Navigation);
