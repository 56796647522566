import React from 'react';
import PropTypes from 'prop-types';
import { MDBInput } from 'mdbreact';

const InputText = ({ name, label, value, error, type, onChange }) => (
  <MDBInput
    name={name}
    type={type}
    label={label}
    value={value}
    onChange={onChange}
    className={error ? 'is-invalid' : ''}
  >
    {!error ? null : (<div className="invalid-feedback">{error}</div>)}
  </MDBInput>
);

InputText.defaultProps = {
  value: ''
};

InputText.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string
};

export default InputText;
