export default (fn, ms) => {
  let isCooldown = false;

  return () => {

    if ( isCooldown ) return;

    isCooldown = true;

    fn();

    setTimeout(() => isCooldown = false, ms);
  }
}
