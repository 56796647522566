import React from 'react';
import { notification } from './../../actions/App';
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
  MDBBtn
} from 'mdbreact';

const NavbarTop = (props) => {
  const navStyle = {
    paddingLeft:
      props.windowWidth > props.breakWidth ? '210px' : '16px'
  };

  const specialCaseNavbarStyles = {
    WebkitBoxOrient: 'horizontal',
    flexDirection: 'row'
  };

  const onPayin = () => {
    props.payInModalToggle({ isOpen: true });
  }

  return (
    <MDBNavbar style={navStyle} double expand="md" fixed="top" scrolling>
      <MDBNavbarNav left>
        <MDBNavItem>
          <div
            onClick={props.navbarLeftToggle}
            key="sideNavToggleA"
            style={{
              lineHeight: "32px",
              marginRight: "1em",
              verticalAlign: "middle"
            }}
          >
            <MDBIcon icon="bars" color="white" size="2x" />
          </div>
        </MDBNavItem>
        <MDBNavItem className="d-none d-md-inline" style={{ paddingTop: 5 }}></MDBNavItem>
      </MDBNavbarNav>
      <MDBNavbarNav right style={specialCaseNavbarStyles}>
        <MDBNavItem>
          <MDBNavLink to="/cabinet/transactions">
            <MDBIcon fas icon="hryvnia" className="d-inline-inline" />{' '}
            <div className="d-none d-md-inline">{props.balance}</div>
          </MDBNavLink>
        </MDBNavItem>
        <MDBNavItem>
          <MDBBtn color="grey" onClick={onPayin}>
            <MDBIcon icon="plus" className="d-inline-inline" /> ПОПОВНИТИ РАХУНОК
          </MDBBtn>
        </MDBNavItem>
      </MDBNavbarNav>
    </MDBNavbar>
  );
};

export default NavbarTop;
