import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Navigation from './../containers/Navigation/Cabinet';
import Models from './../containers/User/Posts';
import Profile from './../containers/User/Profile';
import Transactions from './../containers/Transactions';

const Cabinet = () => (
  <Navigation>
    <Switch>
      <Route exact path="/cabinet/profile" component={Profile} />
      <Route exact path="/cabinet/posts" component={Models} />
      <Route exact path="/cabinet/transactions" component={Transactions} />
    </Switch>
  </Navigation>
);

export default Cabinet;
