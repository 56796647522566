import * as types from './../constants/ActionTypesPosts';

const initialState = {
  pagination: {
    currentPage: 1,
    pagesCount: 1,
    rowsCount: 0
  },
  loading: false,
  list: []
};

export default (state = initialState, action) => {
  let list = [];

  switch ( action.type ) {

    case types.POSTS_FETCHED:
      return {
        list: state.list.concat(action.payload.list),
        loading: false
      };

    case types.POSTS_FAVORITES_ADD:
      list = [ ...state.list ];
      list.map(el => el.isFavorite = el.id === action.payload.id ? true : el.isFavorite);
      return { ...state, list: list };

    case types.POSTS_FAVORITES_REMOVE:
      list = [ ...state.list ];
      list.map(el => el.isFavorite = el.id === action.payload.id ? false : el.isFavorite);
      return { ...state, list: list };

    case types.POSTS_LOADING:
      return { ...state, loading: action.payload };

    case types.POSTS_CLEAR:
      return { ...state, list: [] };

    default:
      return state;
  }
};
