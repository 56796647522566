import * as types from './../constants/ActionTypesPosts';

const initialState = {
  filtersValues: {},
  hashtags: [],
  limit: 50,
  page: 1,
  orderBy: '-id',
  favorites: false
};

export default (state = initialState, action) => {
  switch ( action.type ) {

    case types.POSTS_FILTERS_SET:
      return action.payload;

    default:
      return state;
  }
};
