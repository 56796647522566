import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ArrowLeft, ArrowRight } from './../Icons';
import { Image, Model } from './Slides';

const PreviewWrapper = styled('div')``;

const generateSlides = (files) => {
  let modelAndroid = null;
  let modelIos = null;
  const slides = [];

  files.forEach((el) => {
    if ( el.type === 'model3D-android' ) modelAndroid = el;
    if ( el.type === 'model3D-ios' ) modelIos = el;
  });

  if ( !!modelAndroid ) {
    slides.push({
      preview: 'https://api.shusobi.com/uploads/models/model-3d-preview.png',
      slide: (<Model android={modelAndroid.source} ios={modelIos?.source} />),
      type: 'model3D',
    });
  }

  files.forEach((el) => {
    if ( el.type === 'image' ) {
      slides.push({
        preview: el.source,
        slide: (<Image source={el.source} />),
        type: 'image',
      })
    }
  });

  return slides;
};

const ImageGallery = ({ className, files }) => {
  const [ selectedIndex, setSelectedIndex ] = useState(0);
  const slides = useMemo(() => generateSlides(files), [ files ]);
  const lastIndex = slides.length - 1;

  const onLeft = () => setSelectedIndex(selectedIndex ? selectedIndex - 1 : lastIndex);

  const onRight = () => setSelectedIndex(selectedIndex === lastIndex ? 0 : selectedIndex + 1);

  const onSelect = (index) => () => setSelectedIndex(index);

  return (
    <div className={className}>
      <div className="avatar-wrapper">
        <ArrowLeft onClick={onLeft} />
        <ArrowRight onClick={onRight} />
        {slides[selectedIndex].slide}
      </div>
      <div className="preview-panel-wrapper">
        <div className="preview-panel">
          {slides.map(({ preview }, index) => (
            <PreviewWrapper
              className={selectedIndex === index ? 'selected' : ''}
              key={index}
              onClick={onSelect(index)}
            >
              <img className="preview" src={preview} alt="Preview" />
            </PreviewWrapper>
          ))}
        </div>
      </div>
      <div className="slider-border-left" />
      <div className="slider-border-right" />
    </div>
  );
};

ImageGallery.propTypes = {
  className: PropTypes.string,
  files: PropTypes.array.isRequired,
};

export default styled(ImageGallery)`
  display: grid;
  grid-template-rows: 1fr 50px;
  width: 100%;

  .avatar-wrapper {
    background-color: #fff;
    border-radius: 0 2rem 0 0;
    height: 100%;
    min-height: 40vh;
    position: relative;
    width: 100%;
    z-index: 10;

    ${ArrowLeft}, ${ArrowRight} {
      cursor: pointer;
      position: absolute;
      top: 40%;
      z-index: 10;
    }

    ${ArrowLeft} {
      left: 2rem;
    }

    ${ArrowRight} {
      right: 2rem;
    }

    ${Image} {
      border-radius: 0 2rem 0 0;
    }
  }

  .preview-panel-wrapper {
    background-color: #fff;
    border-radius: 0 0 0 2rem;
    overflow: hidden;
    overflow-x: scroll;
    position: relative;
    width: 100%;
    z-index: 10;

    .preview-panel {
      height: 100%;
      margin: 0 auto;
      width: max-content;

      ${PreviewWrapper} {
        align-items: center;
        border: solid 2px transparent;
        box-sizing: border-box;
        cursor: pointer;
        display: inline-flex;
        height: 100%;
        max-height: 100%;
        transition: all 300ms ease-out;

        &.selected {
          border-color: #4f4c4d;
        }

        .preview {
          max-height: 100%;
          max-width: 100%;
        }
      }
    }
  }

  .slider-border-left {
    background-color: #f3f3f3;
    border-radius: 0 0 0 2rem;
    bottom: -1px;
    left: -1px;
    position: absolute;
    top: 0;
    width: 70%;
    z-index: 5;
  }

  .slider-border-right {
    background-color: #f3f3f3;
    bottom: 0;
    top: -1px;
    position: absolute;
    right: -1px;
    width: 70%;
    z-index: 5;
  }

  @media (min-width: 470px) {
    & {
      grid-template-rows: 1fr 55px;
    }
  }

  @media (min-width: 780px) {
    & {
      grid-template-rows: 1fr 60px;
    }
  }

  @media (min-width: 1024px) and (min-height: 600px) {
    & {
      grid-template-rows: 1fr 65px;
    }
  }

  @media (min-width: 1024px) and (min-height: 768px) {
    & {
      grid-template-rows: 1fr 70px;
    }
  }

  @media (min-width: 1600px) and (min-height: 900px) {
    & {
      grid-template-rows: 1fr 75px;
    }
  }

  @media (min-width: 1600px) and (min-height: 1024px) {
    & {
      grid-template-rows: 1fr 80px;
    }
  }
`;
