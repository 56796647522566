import { connect } from 'react-redux';
import { create, createModalToggle } from './../../actions/Contacts';
import CreateModal from './../../components/Contacts/CreateModal';

const mapState = state => ({
  isOpen: state.modals.contactsCreate.isOpen,
  user: state.user.data
});

export default connect(mapState, { create, createModalToggle })(CreateModal);
