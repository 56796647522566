import React, { Fragment } from 'react';
import ContactsCreateModal from './../containers/Contacts/CreateModal';
import PostsCreateModal from './../containers/Posts/CreateModal';
import PayInModal from './../containers/Balance/PayInModal';
import Footer from './../containers/Footer';

export default () => (
  <Fragment>
    <ContactsCreateModal />
    <PostsCreateModal />
    <PayInModal />
    <Footer />
  </Fragment>
);
