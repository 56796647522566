import * as types from './../constants/ActionTypesColors';

const initialState = {};

export default (state = initialState, action) => {
  switch ( action.type ) {

    case types.COLORS_FETCH:
      return { ...action.payload };

    default:
      return state;
  }
};
