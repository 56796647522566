import React, { Fragment } from 'react';
import Card from './../Atoms/Card';
import Loader from './../Atoms/Loader';
import useFilters from './../../hooks/useFilters';
import Posts from './../../containers/Posts/Tiles';
import HashtagsInput from './../HashtagsInput';
import { MDBIcon } from 'mdbreact';

const initialFilters = {
  hashtags: [],
  isPostPurcahsed: true,
  limit: 500,
};

const Reservations = ({ hashtags, search }) => {
  const { data, filters, filtersHandler, orderHandler } = useFilters({
    fetchData: search,
    initialFilters,
  });

  const hashtagsHandler = (hashtags) => filtersHandler({ hashtags });

  return (
    <Card
      title="Моделі"
      body={(
        <Fragment>
          <HashtagsInput hashtags={hashtags} hashtagsHandler={hashtagsHandler} />
          {data.loading && <Loader />}
          <Posts list={data.list} blanc={true} />
        </Fragment>
      )}
    />
  );
};

export default Reservations;
