import { CONTACTS_CREATE_MODAL_TOGGLE } from './../constants/ActionTypesContacts';
import { POSTS_CREATE_MODAL_TOGGLE } from './../constants/ActionTypesPosts';
import { PAY_IN_MODAL_TOGGLE } from './../constants/ActionTypesBalance';
import { FOOTER_TOGGLE } from './../constants/ActionTypesFooter';

const initialState = {
  contactsCreate: {
    isOpen: false
  },
  footer: {
    isOpen: false,
  },
  postsCreate: {
    isOpen: false
  },
  payIn: {
    isOpen: false,
    values: {},
    buyText: false,
  },
};

export default (state = initialState, action) => {
  let nextState = { ...state };

  switch ( action.type ) {

    case CONTACTS_CREATE_MODAL_TOGGLE:
      nextState.contactsCreate = action.payload;
      return nextState;

    case FOOTER_TOGGLE:
      nextState.footer = action.payload;
      return nextState;

    case POSTS_CREATE_MODAL_TOGGLE:
      nextState.postsCreate = action.payload;
      return nextState;

    case PAY_IN_MODAL_TOGGLE:
      nextState.payIn = { ...initialState.payIn, ...action.payload };
      return nextState;

    default:
      return nextState;
  }
};
